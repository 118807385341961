import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isMobile } from '../utils/environment'

export default class Footer {
  constructor(scrollbar) {
    this.scrollbar = scrollbar
    this.outer = document.querySelector('.c-footer')
    this.inner = document.querySelector('.c-footer-inner')
    this.up = document.querySelector('.c-footer-up')

    this.init()
  }

  init() {
    this.up.addEventListener('click', () => {
      this.scrollbar.scrollTo(0, 0, 2000)
    })

    if (!isMobile) {
      ScrollTrigger.create({
        trigger: this.outer,
        animation: this.timeline(),
        scrub: true,
        start: 'top bottom',
        end: () => `+=${this.outer.clientHeight}`
      })

      this.linkHover()
    }
  }

  linkHover() {
    function handleLinkEnter(link) {
      gsap.to(link.querySelectorAll('.char'), {
        opacity: 1,
        color: '#fff',
        duration: 0.5,
        // overwrite: true,
        stagger: { each: 0.1 }
      })
    }

    function handleLinkLeave(link) {
      gsap.to(link.querySelectorAll('.char'), {
        opacity: 0.5,
        color: '#eae9e4',
        duration: 0.5,
        // overwrite: true,
        stagger: { each: 0.1 }
      })
    }

    const links = document.querySelectorAll('.c-footer-list a')

    links.forEach(link =>
      link.addEventListener('mouseenter', () => handleLinkEnter(link))
    )
    links.forEach(link =>
      link.addEventListener('mouseleave', () => handleLinkLeave(link))
    )
  }

  timeline() {
    return gsap
      .timeline()
      .set(this.inner, { willChange: 'transform' })
      .fromTo(
        this.inner,
        { y: '-50%' },
        { y: '0%', ease: 'none', duration: 0.4 }
      )
      .set(this.inner, { willChange: 'auto' })
  }
}
