import { /* preloadFonts, */ preloadImages } from './utils/preload'
import { html, isMobile } from './utils/environment'

import Cinema from './modules/Cinema'
import Focus from './modules/Focus'
import Footer from './modules/Footer'
import Hero from './modules/Hero'
import Marquee from './modules/Marquee'
import Menu from './modules/Menu'
import Reel from './modules/Reel'
import Rotator from './modules/Rotator'
import Scroll from './modules/Scroll'
import Theme from './modules/Theme'
import Services from './modules/Services'
import Magnetic from './modules/Magnetic'
import Split from './modules/Split'
import Parallax from './modules/Parallax'
import Credits from './modules/Credits'
// import Posts from './modules/Posts'
import Title from './modules/Title'
import Anchors from './modules/Anchors'
import Music from './modules/Music'
import Modal from './modules/Modal'
import Cursor from './modules/Cursor'
import Preloader from './modules/Preloader'
import emitter from './modules/Emitter'

class Main {
  init() {
    html.classList.replace('is-loading', 'is-loaded')
    if (isMobile) html.classList.add('is-mobile')

    this.music = new Music()
    this.cursor = new Cursor()
    this.preloader = new Preloader({
      music: this.music,
      cursor: this.cursor
    })

    this.scroll = new Scroll()
    this.hero = new Hero()

    emitter.on('showtime', () => {
      this.split = new Split()
      this.menu = new Menu()
      this.reel = new Reel()
      this.cinema = new Cinema()
      this.focus = new Focus()
      this.marquee = new Marquee(this.scroll.scrollbar)
      this.rotator = new Rotator(this.scroll.scrollbar)
      this.theme = new Theme()
      this.footer = new Footer(this.scroll.scrollbar)
      this.services = new Services()
      this.credits = new Credits()
      this.parallax = new Parallax()
      // this.posts = new Posts()
      this.title = new Title()
      this.modal = new Modal({ music: this.music })
      this.anchors = new Anchors(this.scroll.scrollbar)
      this.magnetic = new Magnetic()
    })
  }
}

const main = new Main()
// main.init()

// document.fonts.ready.then(() => {})

// TODO: Load Ivy Mode font
Promise.all([
  preloadImages('img'),
  // preloadFonts(['IBM Plex Sans']),
  document.fonts.ready
]).then(() => {
  main.init()
})
