import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isMobile } from '../utils/environment'

export default class Cinema {
  constructor() {
    this.outer = document.querySelector('.c-cinema')
    this.content = document.querySelector('.c-cinema-content')

    if (!isMobile) this.init()
  }

  init() {
    ScrollTrigger.create({
      trigger: this.outer,
      start: 'top top',
      end: '+=120%',
      scrub: 1,
      pin: true,
      pinType: 'transform',
      animation: this.timeline()
    })
  }

  timeline() {
    return gsap
      .timeline()
      .fromTo(this.content, { yPercent: 120 }, { yPercent: -180 })
      .fromTo(this.content, { autoAlpha: 1 }, { autoAlpha: 0 }, '<15%')
    // return gsap.timeline().to(this.content.childNodes, {
    //   autoAlpha: 0,
    //   yPercent: -20,
    //   delay: 0.5,
    //   stagger: { each: 0.35 }
    // })
  }
}
