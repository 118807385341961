import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isMobile } from '../utils/environment'

export default class Reel {
  constructor() {
    this.el = document.querySelector('.c-hmd')
    this.columns = document.querySelectorAll('.c-reel-col')

    if (!isMobile) this.init()
  }

  init() {
    ScrollTrigger.create({
      trigger: this.el,
      start: 'top top',
      // end: 'bottom top',
      end: '+=250%',
      scrub: true,
      pin: true,
      pinType: 'transform',
      animation: this.timeline()
    })
  }

  timeline() {
    return gsap
      .timeline()
      .set(this.columns, { willChange: 'transform' })
      .fromTo(this.columns[0], { y: '0%' }, { y: '-100%', ease: 'linear' })
      .fromTo(this.columns[1], { y: '-100%' }, { y: '0', ease: 'linear' }, '<')
      .set(this.columns, { willChange: 'auto' })
  }
}
