import imagesLoaded from 'imagesloaded'
import WebFont from 'webfontloader'

// https://github.com/desandro/imagesloaded
export const preloadImages = (selector = 'img') =>
  new Promise(resolve => {
    imagesLoaded(
      document.querySelectorAll(selector),
      { background: true },
      resolve
    )
  })

// https://github.com/typekit/webfontloader
export const preloadFonts = id =>
  new Promise(resolve => {
    WebFont.load({
      google: {
        families: id
      },
      active: resolve
    })
  })
