import { gsap } from 'gsap'

export default class Cursor {
  constructor() {
    this.el = document.querySelector('[data-cursor]')
    this.speed = 0.5
    this.visible = true
    this.init()
  }

  init() {
    this.handleMouseMove = e => {
      this.pos = {
        x: e.clientX,
        y: e.clientY
      }

      this.update()
    }

    document.body.addEventListener('mousemove', this.handleMouseMove)

    this.move(-500, -500)
  }

  update() {
    this.move()
  }

  move(x, y, speed) {
    gsap.to(this.el, {
      x: x || this.pos.x,
      y: y || this.pos.y,
      rotate: gsap.utils.mapRange(
        0,
        window.innerWidth,
        -20,
        20,
        x || this.pos.x
      ),
      force3D: true,
      overwrite: true,
      ease: 'expo.out',
      duration: this.visible ? speed || this.speed : 0
    })
  }

  destroy() {
    document.body.removeEventListener('mousemove', this.handleMouseMove)

    gsap.to(this.el, {
      autoAlpha: 0,
      duration: 0.25,
      onComplete: () => {
        this.el.remove()
      }
    })
  }
}
