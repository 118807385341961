import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import emitter from './Emitter'

// gsap.registerPlugin(GSDevTools)

export default class Hero {
  constructor() {
    this.outer = document.querySelector('.c-gallery-outer')
    this.mask = document.querySelector('.c-gallery-overlay')
    this.left = document.querySelectorAll('.c-gallery-left')
    this.right = document.querySelectorAll('.c-gallery-right')
    this.title = document.querySelector('.c-gallery-title')
    this.headline = document.querySelector('.c-hero-title')
    this.hero = document.querySelector('.c-hero')
    this.copy = document.querySelector('.c-hero-copy')
    this.video = document.querySelector('[data-video="reel"]')

    this.leftImages = document.querySelectorAll('.c-gallery-left img')
    this.rightImages = document.querySelectorAll('.c-gallery-right img')

    this.init()
  }

  init() {
    gsap.set([this.outer, this.hero], { autoAlpha: 0 })

    emitter.on('showtime', () => {
      this.addIntroAnimation()
      this.addScrollTrigger()
    })

    // GSDevTools.create({ animation: 'intro', hideGlobalTimeline: true })
  }

  addIntroAnimation() {
    gsap
      .timeline({
        id: 'intro',
        defaults: { ease: 'expo' }
      })
      .set([this.outer, this.hero], { autoAlpha: 1 })
      .set(this.mask, {
        scale: 0,
        autoAlpha: 0,
        y: -400
      })
      .set([...this.leftImages, ...this.rightImages], {
        y: -280,
        autoAlpha: 0,
        scale: 0
      })
      .to(this.mask, {
        scale: 1,
        duration: 1,
        ease: 'expo',
        autoAlpha: 1
      })
      .to(
        [...this.leftImages, ...this.rightImages],
        {
          scale: 1,
          autoAlpha: 1,
          duration: 0.5,
          ease: 'expo',
          stagger: { each: 0.075, from: 'center' }
        },
        '<8%'
      )
      .to(this.mask, {
        y: 0,
        autoAlpha: 1,
        duration: 1.25,
        ease: 'expo.out'
      })
      .to(
        [...this.leftImages, ...this.rightImages],
        {
          y: 0,
          autoAlpha: 1,
          duration: 1,
          ease: 'expo.out',
          stagger: { each: 0.03, from: 'end' }
        },
        '<5%'
      )
      .from(
        this.headline,
        { autoAlpha: 0, duration: 2, ease: 'expo', y: -100 },
        '<10%'
      )
      .from(
        this.copy,
        { autoAlpha: 0, duration: 2, ease: 'expo', y: -40 },
        '<10%'
      )
  }

  addScrollTrigger() {
    ScrollTrigger.create({
      trigger: this.outer,
      start: 'top top',
      end: '+=1500px',
      // end: 'bottom +=3000px',
      animation: this.scrollTimeline(),
      scrub: true,
      pin: true,
      pinType: 'transform'
    })
  }

  scrollTimeline() {
    return gsap
      .timeline({ onStart: this.playVideo.bind(this) })
      .fromTo(
        this.mask,
        { clipPath: 'inset(15% 35% 15% 35% round 20px)' },
        {
          clipPath: 'inset(0% 6% 0% 6% round 20px)',
          ease: 'linear',
          duration: 1
        }
      )
      .fromTo(
        this.left[0],
        { xPercent: 0 },
        { xPercent: -200, ease: 'linear', duration: 1 },
        '<'
      )
      .fromTo(
        this.left[1],
        { xPercent: 0 },
        { xPercent: -200, ease: 'linear', duration: 1 },
        '<'
      )
      .fromTo(
        this.right,
        { xPercent: 0 },
        { xPercent: 200, ease: 'linear', duration: 1 },
        '<'
      )
      .fromTo(
        this.title,
        { opacity: 0, y: 200, rotate: '2deg' },
        { opacity: 1, y: 0, rotate: 0, duration: 1, ease: 'linear' },
        '<.5'
      )
  }

  playVideo() {
    this.video.currentTime = 1.2 // time for initial frame in seconds
    this.video.play()
  }
}
