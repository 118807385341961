import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

export default class Marquee {
  constructor(scrollbar) {
    this.scrollbar = scrollbar
    this.el = document.querySelector('.c-marquee-wrap')
    this.row = document.querySelectorAll('.c-marquee-inner')
    this.init()
  }

  init() {
    const scrollbar = this.scrollbar
    let tl = this.tlMarquee()
    // tl.time(100000) // https://greensock.com/docs/v3/GSAP/Timeline/time()
    let currentScrollY = scrollbar.scrollTop
    let raf = null
    let reverse = false

    // window.addEventListener('resize', () => tl.invalidate())

    function render() {
      var newScrollY = scrollbar.scrollTop
      var delta = newScrollY - currentScrollY
      // var factor = Math.max(Math.abs(0.8 * delta), 1)
      var factor = Math.max(Math.abs(0.2 * delta), 1)
      tl.timeScale(factor)
      reverse && tl.reverse()
      currentScrollY = newScrollY
      raf = requestAnimationFrame(render)
    }

    ScrollTrigger.create({
      trigger: this.el,
      start: 'top bottom',
      end: 'bottom top',
      onToggle: function (self) {
        if (self.isActive) {
          tl.play()
          render()
        } else {
          tl.pause()
          cancelAnimationFrame(raf)
        }
      },
      onUpdate: function (self) {
        reverse = self.direction === -1
        tl.reversed(reverse)
      }
    })
  }

  tlMarquee() {
    return gsap
      .timeline({ paused: true })
      .set(this.row, { willChange: 'transform' }, 0)
      .fromTo(
        this.row[0],
        { x: '0%' },
        {
          x: '-50%',
          ease: 'none',
          duration: 40,
          repeat: -1
        },
        0
      )
      .fromTo(
        this.row[1],
        { x: '0%' },
        {
          x: '-50%',
          ease: 'none',
          duration: 20,
          repeat: -1
        },
        0
      )
      .set(this.row, {
        willChange: 'auto'
      })
  }
}
