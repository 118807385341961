// import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isMobile } from '../utils/environment'

export default class Services {
  constructor() {
    this.body = document.querySelector('.c-services-body')
    this.list = document.querySelector('.c-services-list')

    if (!isMobile) this.init()
  }

  init() {
    ScrollTrigger.create({
      trigger: this.body,
      endTrigger: this.list,
      start: 'top top',
      end: 'bottom center',
      pin: true,
      pinType: 'transform'
    })
  }
}
