import { html } from '../utils/environment'
import { gsap } from 'gsap'
import Player from '@vimeo/player'

export default class Modal {
  constructor({ music }) {
    this.container = document.querySelector('.modal')

    this.inner = this.container.querySelector('.modal-inner')
    this.iframe = this.container.querySelector('.modal-player')
    // this.spinner = this.container.querySelector('.modal-spinner')
    this.closeButton = this.container.querySelector('.modal-close')
    // this.toggle = this.container.querySelector('.toggle')

    this.isOpen = false
    this.isLoading = true

    this.music = music
    this.timeline = null

    this.init()
  }

  init() {
    this.addEscapeListener()
    this.addCloseButton()
    this.addVideos()
  }

  addEscapeListener() {
    this.closeBind = event => {
      if (this.isOpen && event.key === 'Escape') this.close()
    }

    document.addEventListener('keyup', this.closeBind)
  }

  addCloseButton() {
    this.closeButton.addEventListener('click', () => {
      if (this.isOpen) this.close()
    })
  }

  addVideos() {
    const attr = `data-video`
    const videos = [...document.querySelectorAll(`[${attr}]`)]

    videos.forEach(video => {
      video.addEventListener('click', event => {
        event.preventDefault()

        const id = video.getAttribute(attr)

        if (id) {
          this.addVideo(id)
          this.open()
        }
      })
    })
  }

  addVideo(id) {
    // https://www.npmjs.com/package/@vimeo/player

    if (this.player) this.player.destroy()

    this.player = new Player('player', {
      url: `https://vimeo.com/${id}`,
      autoplay: false,
      controls: true,
      responsive: false,
      title: false,
      loop: false,
      byline: false,
      dnt: true,
      color: 'b2aea8'
    })

    // this.player
    //   .setColor('#b2aea8')
    //   .then(color => {
    //     console.log('color setted')
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   })

    // this.showLoading()

    this.player.on('ended', () => {
      setTimeout(() => {
        if (this.isOpen) this.close()
      }, 1500)
    })

    // this.player.on('timeupdate', currentTime => {
    //   console.log(currentTime)
    //   const progress = this.container.querySelector('.progress')
    //   progress.style.transform = `scaleX(${currentTime.percent})`
    //   // progress.setAttribute('title', `${Math.round(currentTime.seconds)} s.`)
    // })

    // this.addControls()

    // this.player.on('play', () => {
    //   // this.hideLoading()

    // })
  }

  // addControls() {
  //   this.toggle.addEventListener('click', () => {
  //     if (!this.player) return

  //     if (this.isPlaying) {
  //       this.player.pause()
  //       this.toggle.textContent = 'Play'
  //       this.isPlaying = false
  //     } else {
  //       this.player.play()
  //       this.toggle.textContent = 'Pause'
  //       this.isPlaying = true
  //     }
  //   })
  // }

  open() {
    this.isOpen = true
    html.classList.add('has-modal')

    this.timeline = gsap
      .timeline()
      .set(this.closeButton, { autoAlpha: 1 })
      .fromTo(
        this.container,
        { autoAlpha: 0, display: 'none' },
        {
          autoAlpha: 1,
          display: 'block',
          duration: 0.75,
          ease: 'linear'
        }
      )
      .fromTo(
        this.iframe,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.5, delay: 0.25, ease: 'linear' }
      )
    // .from(this.closeButton, { autoAlpha: 0, duration: 0.5 })

    if (this.music.isPlaying) this.music.pause()

    if (this.player) {
      this.player.play()
      this.isPlaying = true
      // this.toggle.textContent = 'Pause'
    }
  }

  close() {
    this.isOpen = false
    html.classList.remove('has-modal')

    gsap.to(this.closeButton, { autoAlpha: 0 })
    this.timeline.reverse(0)

    if (!this.music.isPlaying && this.music.enable) this.music.play()

    if (this.player) {
      this.player.pause()
      this.isPlaying = false
      // this.toggle.textContent = 'Play'
    }
  }

  // hideLoading() {
  //   this.isLoading = false
  //   this.spinner.classList.add('-hide')
  // }

  // showLoading() {
  //   this.isLoading = true
  //   this.spinner.classList.remove('-hide')
  // }
}
