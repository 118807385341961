import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class Theme {
  constructor() {
    this.root = document.documentElement
    this.defaultTheme = 'light'
    this.sections = gsap.utils.toArray('body [data-theme]') // exclude html tag
    this.init()
  }

  setTheme(theme) {
    this.root.setAttribute('data-theme', theme)
  }

  init() {
    this.setTheme(this.defaultTheme) // default theme

    this.sections.forEach(section => {
      const currentTheme = section.dataset.theme

      ScrollTrigger.create({
        trigger: section,
        start: 'top 1px', // 1px for initial top section
        end: 'bottom top',
        onEnter: () => this.setTheme(currentTheme),
        onEnterBack: () => this.setTheme(currentTheme),
        onLeave: () => this.setTheme(this.defaultTheme),
        onLeaveBack: () => this.setTheme(this.defaultTheme)
      })
    })
  }
}
