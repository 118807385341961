import { gsap } from 'gsap'
import { html } from '../utils/environment'

const HAS_MENU_CLASS = 'has-menu'

export default class Menu {
  constructor() {
    this.menu = document.querySelector('.c-menu')
    this.inner = document.querySelector('.c-menu-inner')
    this.nav = document.querySelector('.c-menu-nav')
    this.social = document.querySelector('.c-menu-social')
    this.$toggle = document.querySelectorAll('[data-menu="toggle"]')
    this.$close = document.querySelectorAll('[data-menu="close"]')
    this.$open = document.querySelectorAll('[data-menu="open"]')
    this.$links = this.menu.querySelectorAll('a')
    this.isOpen = false
    this.init()
  }

  init() {
    this.$toggle.forEach(el =>
      el.addEventListener('click', () => this.toggle())
    )
    this.$close.forEach(el => el.addEventListener('click', () => this.close()))
    this.$open.forEach(el => el.addEventListener('click', () => this.open()))

    // TODO: close menu when you click on a link (wait close animation before scroll)
    this.$links.forEach(el => el.addEventListener('click', () => this.close()))

    this.closeBind = event => {
      if (this.isOpen && event.key === 'Escape') {
        this.close()
      }
    }

    document.addEventListener('keyup', this.closeBind)
  }

  destroy() {
    document.removeEventListener('keyup', this.closeBind)
  }

  open() {
    this.isOpen = true
    html.classList.add(HAS_MENU_CLASS)

    gsap
      .timeline()
      .set(this.menu, { display: 'block' })
      .set(this.inner, { autoAlpha: 1 })
      .fromTo(
        this.menu,
        { clipPath: `inset(0% 100%)` },
        { clipPath: `inset(0% 0%)`, duration: 0.5, ease: 'linear' }
      )
      .fromTo(
        this.nav.querySelectorAll('div'),
        { x: 250, autoAlpha: 0 },
        {
          x: 0,
          autoAlpha: 1,
          duration: 1,
          ease: 'sine.inOut',
          stagger: { each: 0.2 }
        },
        '<0.1'
      )
      .fromTo(
        this.social,
        { x: -250, autoAlpha: 0 },
        { x: 0, autoAlpha: 1, duration: 1, delay: 0.25, ease: 'sine.inOut' },
        '<'
      )
  }

  close() {
    this.isOpen = false
    html.classList.remove(HAS_MENU_CLASS)

    gsap
      .timeline()
      // .to(this.inner, { autoAlpha: 0, duration: 0.15 })
      .fromTo(
        this.menu,
        { clipPath: `inset(0% 0%)` },
        {
          clipPath: `inset(0% 100%)`,
          duration: 0.5,
          ease: 'linear'
        }
      )
      .set(this.menu, { display: 'none' })
  }

  toggle() {
    this.isOpen = !this.isOpen

    if (this.isOpen) {
      this.open()
    } else {
      this.close()
    }
  }
}
