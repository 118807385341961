import { html } from '../utils/environment'

export default class Music {
  constructor() {
    this.button = document.querySelector('.c-header-audio')
    this.audio = document.querySelector('#music')
    this.isPlaying = false
    this.isInitialized = false
    this.enable = true
    this.init()
  }

  init() {
    this.button.addEventListener('click', e => {
      if (this.isPlaying) {
        this.pause()
        this.enable = false
      } else {
        this.play()
        this.enable = true
      }
    })
  }

  play() {
    this.audio.volume = 0.2
    this.audio.play()
    this.isPlaying = true
    html.classList.toggle('has-sound')
  }

  pause() {
    this.audio.pause()
    this.isPlaying = false
    html.classList.toggle('has-sound')
  }
}
