/*!
 * SmoothScrollbar GSAP ScrollTrigger Plugin
 *
 * @version 1.0.1
 * @author Artem Dordzhiev (Draft)
 */

import Scrollbar from 'smooth-scrollbar'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

class ScrollTriggerPlugin extends Scrollbar.ScrollbarPlugin {
  constructor(scrollbar, options) {
    super(scrollbar, options)
    this.setProxy()
  }

  setProxy() {
    const scrollbar = this.scrollbar

    ScrollTrigger.scrollerProxy(document.body, {
      // ScrollTrigger.scrollerProxy('.scroller', {
      scrollTop(value) {
        if (arguments.length) {
          scrollbar.scrollTop = value // setter
        }
        return scrollbar.scrollTop // getter
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }
      },
      pinType: 'transform',
    })

    // ScrollTrigger.scrollerProxy(".scroller", {
    //     scrollTop(value) {
    //       if (arguments.length) {
    //         scrollbar.scrollTop = value;
    //       }
    //       return scrollbar.scrollTop;
    //     }
    //   });

    scrollbar.addListener(ScrollTrigger.update)

    // ScrollTrigger.defaults({ scroller: document.querySelector(".scroller") });

    // // Only necessary to correct marker position - not needed in production
    // if (document.querySelector('.gsap-marker-scroller-start')) {
    //   const markers = gsap.utils.toArray('[class *= "gsap-marker"]')

    //   scrollbar.addListener(({ offset }) => {
    //     gsap.set(markers, { marginTop: -offset.y })
    //   })
    // }

    // scrollbar.addListener(() => {
    //     ScrollTrigger.refresh()
    //   })
  }
}

ScrollTriggerPlugin.pluginName = 'ScrollTrigger'

export default ScrollTriggerPlugin
