import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

export default class Rotator {
  constructor(scrollbar) {
    this.scrollbar = scrollbar
    this.el = document.querySelectorAll('.c-film')
    this.row = document.querySelectorAll('.c-film-rotator')
    this.init()
  }

  init() {
    const scrollbar = this.scrollbar
    let tl = this.tlMarquee()
    // tl.time(100000) // https://greensock.com/docs/v3/GSAP/Timeline/time()
    let currentScrollY = scrollbar.scrollTop
    let raf = null
    let reverse = false

    // window.addEventListener('resize', () => tl.invalidate())

    function render() {
      var newScrollY = scrollbar.scrollTop
      var delta = newScrollY - currentScrollY
      // var factor = Math.max(Math.abs(0.8 * delta), 1)
      var factor = Math.max(Math.abs(0.95 * delta), 1)
      tl.timeScale(factor)
      reverse && tl.reverse()
      currentScrollY = newScrollY
      raf = requestAnimationFrame(render)
    }

    ScrollTrigger.create({
      trigger: this.el,
      start: 'top bottom',
      end: 'bottom top',
      onToggle: function (self) {
        if (self.isActive) {
          tl.play()
          render()
        } else {
          tl.pause()
          cancelAnimationFrame(raf)
        }
      },
      onUpdate: function (self) {
        reverse = self.direction === -1
        tl.reversed(reverse)
      }
    })
  }

  tlMarquee() {
    let tl = new gsap.timeline({ paused: true })

    tl.set(this.row, { willChange: 'transform' }, 0)

    tl.fromTo(
      this.row,
      { rotate: 0 },
      {
        // x: function () {
        //   return window.innerWidth < 1200 ? "-100%" : "-66.67%";
        // },
        rotate: 360,
        ease: 'none',
        duration: 60,
        repeat: -1
      }
    )

    // todas las filas distintos time

    tl.set(this.row, {
      willChange: 'auto'
    })

    return tl
  }
}
