import SmoothScrollbar from 'smooth-scrollbar'
import SoftScrollPlugin from '../vendor/SoftScrollPlugin'
import ScrollTriggerPlugin from '../vendor/ScrollTriggerPlugin'
import { html } from '../utils/environment'

SmoothScrollbar.use(ScrollTriggerPlugin, SoftScrollPlugin)

export default class Scroll {
  constructor() {
    this.el = document.querySelector('.o-scroll')
    this.lastOffset = null
    this.init()
  }

  init() {
    this.scrollbar = SmoothScrollbar.init(this.el, {
      renderByPixels: true,
      damping: 0.075,
      alwaysShowTracks: false
      // continuousScrolling: false
    })

    // Disable horizontal scroll
    this.scrollbar.track.xAxis.element.remove()

    // Expose scrollbar
    window.scrollbar = this.scrollbar

    this.el.style.height = '100%'
    html.classList.add('is-smooth')

    this.trackDirection()
  }

  trackDirection() {
    // https://github.com/idiotWu/smooth-scrollbar/issues/169

    this.scrollbar.addListener(({ offset }) => {
      if (offset.y > 20) {
        html.classList.add('has-scrolled')
      } else {
        html.classList.remove('has-scrolled')
      }

      if (!this.lastOffset) {
        this.lastOffset = offset
        return
      }

      const dir = []

      if (offset.y < this.lastOffset.y) {
        dir[0] = 'up'
      } else if (offset.y > this.lastOffset.y) {
        dir[0] = 'down'
      } else {
        dir[0] = 'still'
      }

      this.lastOffset = offset

      switch (dir[0]) {
        case 'up':
          html.setAttribute('data-direction', 'up')
          break
        case 'down':
          html.setAttribute('data-direction', 'down')
          break
        case 'still':
          break
      }
    })
  }
}
