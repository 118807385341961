import { gsap } from 'gsap'

export default class Credits {
  constructor() {
    this.team = document.querySelector('.c-credits-team')
    this.members = document.querySelectorAll('.c-credits-team li')
    this.init()
  }

  init() {
    gsap.from(this.members, {
      x: 40,
      autoAlpha: 0,
      stagger: 0.1,
      delay: 0.35,
      duration: 3,
      ease: 'expo',
      scrollTrigger: { trigger: this.team }
    })
  }
}
