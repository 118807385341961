import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { isMobile } from '../utils/environment'

export default class Focus {
  constructor() {
    this.outer = document.querySelector('.c-focus')
    this.image = document.querySelector('.c-focus-image')
    this.title = document.querySelector('.c-focus-title')
    this.group = document.querySelector('.c-focus-group')
    this.paragraphs = document.querySelectorAll('.c-focus-p')
    this.video = document.querySelector('[data-video="focus"]')
    this.isPlaying = false
    this.init()
  }

  playVideo() {
    document.querySelector('[data-video="focus"]').play()
    this.isPlaying = true
  }

  init() {
    if (!isMobile) {
      ScrollTrigger.create({
        onEnter: this.playVideo.bind(this),
        trigger: this.outer,
        start: 'top top',
        endTrigger: this.group,
        end: '+=200%',
        pinType: 'transform',
        pin: true
      })

      ScrollTrigger.create({
        trigger: this.outer,
        start: 'top top',
        end: 'bottom top',
        // end: '+=150%',
        scrub: true,
        pin: true,
        pinType: 'transform',
        animation: this.timeline()
      })

      ScrollTrigger.create({
        trigger: this.group,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        animation: this.parallax()
      })
    } else {
      ScrollTrigger.create({
        onEnter: this.playVideo.bind(this),
        trigger: this.outer,
        start: 'top top',
        endTrigger: this.group,
        end: '+=100%',
        pinType: 'transform',
        pin: true
      })

      ScrollTrigger.create({
        trigger: this.outer,
        start: 'top top',
        end: 'bottom top',
        // end: '+=150%',
        scrub: true,
        pin: true,
        pinType: 'transform',
        animation: this.timeline()
      })

      ScrollTrigger.create({
        trigger: this.group,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        animation: this.mobileParallax()
      })
    }
  }

  mobileParallax() {
    return gsap
      .timeline()
      .set(this.paragraphs, { willChange: 'transform' })
      .to(this.paragraphs[0], { y: -200 /* xPercent: 50 */ })
      .to(this.paragraphs[1], { y: -200 /* xPercent: -50  */ }, '<')
      .set(this.paragraphs, { willChange: 'auto' })
  }

  parallax() {
    return gsap
      .timeline()
      .set(this.paragraphs, { willChange: 'transform' })
      .to(this.paragraphs[0], { yPercent: -80 /* xPercent: 50 */ })
      .to(this.paragraphs[1], { yPercent: -120 /* xPercent: -50  */ }, '<')
      .set(this.paragraphs, { willChange: 'auto' })
  }

  timeline() {
    return (
      gsap
        .timeline()
        .set(this.image, { willChange: 'clip-path' })
        .fromTo(
          this.image,
          { clipPath: 'circle(100% at 50% 50%)' },
          { clipPath: 'circle(15% at 50% 50%)', ease: 'linear', duration: 5 }
        )
        .fromTo(
          this.title,
          { opacity: 0, y: 200 },
          { opacity: 1, y: 0, ease: 'linear', duration: 2 },
          '<70%'
        )
        // .to(this.image.querySelector('img'), { scale: 0.9, duration: 1 }, '-=.5')
        .set(this.image, { willChange: 'auto' })
    )
  }
}
