import { gsap } from 'gsap'
import { getTranslate } from '../utils/transform'
import { isMobile } from '../utils/environment'

class Magnet {
  constructor(el) {
    this.el = el

    // this.options = Object.assign(
    //   {
    //     y: 0.3,
    //     x: 0.3,
    //     stiffness: 0.2,
    //     restoration: 0.7
    //   },
    //   this.parseOptions(this.el.dataset.magnetic)
    // )
    this.options = {
      // y: 0.3,
      // x: 0.3,
      // stiffness: 0.2,
      // restoration: 0.7
      y: 0.3,
      x: 0.3,
      stiffness: 0.8,
      restoration: 1.5
    }

    // console.log(this.parseOptions(this.el.dataset.magnetic));

    const translate = getTranslate(this.el)

    this.y = translate.y
    this.x = translate.x
    this.width = 0
    this.height = 0

    this.el.addEventListener('mouseenter', e => {
      const { width, height, x, y } = this.el.getBoundingClientRect()

      this.x = x
      this.y = y
      this.width = width
      this.height = height
    })

    this.el.addEventListener('mousemove', e => {
      const x = (e.clientX - this.x - this.width / 2) * this.options.x
      const y = (e.clientY - this.y - this.height / 2) * this.options.y
      this.move(x, y, this.options.stiffness)
    })

    this.el.addEventListener('mouseleave', e => {
      this.move(translate.x, translate.y, this.options.restoration)
    })
  }

  // parseOptions(data) {
  //   let parsed

  //   try {
  //     parsed = JSON.parse(data)
  //   } catch (error) {
  //     console.log(error)
  //     parsed = null
  //   }

  //   return parsed
  // }

  move(x, y, duration) {
    gsap.to(this.el, {
      x,
      y,
      force3D: true,
      overwrite: true,
      duration
    })
  }
}

export default class Magnetic {
  constructor() {
    if (!isMobile) this.init()
  }

  init() {
    const magnets = [...document.querySelectorAll('[data-magnetic]')]
    magnets.forEach(el => new Magnet(el))
  }
}

// Initialize
/**
[...document.querySelectorAll('[data-magnetic]')].forEach(el => {
  new Magnetic(el)
})
 */
