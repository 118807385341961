import { gsap } from 'gsap'

export default class Title {
  constructor() {
    this.elements = gsap.utils.toArray('[data-title]')

    this.init()
  }

  init() {
    this.elements.forEach(element => {
      this.splitted = new SplitText(element, {
        type: 'words,chars',
        charsClass: 'char'
      })

      gsap
        .timeline({
          paused: true,
          delay: 0.1,
          scrollTrigger: {
            trigger: element,
            start: 'top bottom',
            end: 'bottom top'
          }
        })
        .from(this.splitted.chars, {
          autoAlpha: 0,
          x: 20,
          duration: 1,
          stagger: 0.035
        })
    })
  }
}
