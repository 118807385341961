export default class Anchors {
  constructor(scrollbar) {
    this.scrollbar = scrollbar
    this.elements = [...document.querySelectorAll('a')]
    this.init()
  }

  // TODO: Agregar history para las anclas

  init() {
    this.elements.forEach(element => {
      const target = element.getAttribute('href')

      if (target.startsWith('#') && target !== '#') {
        const targetElement = document.querySelector(target)

        element.addEventListener('click', event => {
          if (this.scrollbar) {
            // https://github.com/idiotWu/smooth-scrollbar/blob/develop/docs/api.md#scrollbarscrollintoview
            this.scrollbar.scrollIntoView(targetElement, {
              onlyScrollIfNeeded: true
            })
          } else {
            target.scrollIntoView({
              behavior: 'smooth'
            })
          }
        })
      }
    })
  }
}
