import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitText from '../vendor/SplitText.min.js'
gsap.registerPlugin(SplitText)

export default class Parallax {
  constructor() {
    this.text = document.querySelector('.c-about-text')
    this.elements = gsap.utils.toArray('[data-parallax]')
    this.init()
  }

  init() {
    this.animateImages()
    this.animateText()
  }

  animateImages() {
    this.elements.forEach(element => {
      gsap.from(element, {
        yPercent: parseInt(element.dataset?.parallax) || -50,
        scale: 0.85,
        duration: 1,
        scrollTrigger: {
          trigger: element,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true
        }
      })
    })
  }

  animateText() {
    gsap.from(this.text.querySelectorAll('.char'), {
      autoAlpha: 0,
      x: 10,
      duration: 0.75,
      stagger: 0.03,
      scrollTrigger: {
        trigger: this.text,
        start: '-100px bottom',
        end: 'bottom end'
      }
    })
  }
}
