import { gsap } from 'gsap'
// import { SplitText } from '../vendor/SplitText.min.js'

export default class Split {
  constructor() {
    this.elements = document.querySelectorAll('[data-split]')
    this.init()
  }

  init() {
    this.elements.forEach(el => {
      const type = el.dataset.split || 'words'

      if (type === 'chars') {
        new SplitText(el, { type: 'words,chars', charsClass: 'char' })
      } else if (type === 'lines') {
        new SplitText(el, { type: 'lines', charsClass: 'line' })
      } else {
        new SplitText(el, { type: 'words', wordsClass: 'word' })
      }
    })
  }
}
