import { gsap } from 'gsap'
import emitter from './Emitter'

export default class Preloader {
  constructor({ music, cursor }) {
    this.el = document.querySelector('.c-preloader')
    this.title = document.querySelector('.c-preloader-title')
    this.text = document.querySelector('.c-preloader-text')
    this.music = music
    this.cursor = cursor
    this.done = false
    this.init()
  }

  init() {
    this.handleClick = () => {
      if (this.done) return
      this.music.play()
      this.outro()
      this.done = true
    }

    this.el.addEventListener('click', this.handleClick)
  }

  outro() {
    this.cursor.destroy()

    gsap
      .timeline({
        onStart: () => {
          if (this.text) {
            gsap.to(this.text, { autoAlpha: 0, duration: 0.2, ease: 'linear' })
          }
        }
      })
      .to(this.title.childNodes, {
        autoAlpha: 0,
        duration: 0.25,
        stagger: { each: 0.05, from: 'end' }
      })
      .to(
        this.el,
        {
          yPercent: -100,
          ease: 'power3.in',
          duration: 0.75,
          onComplete: () => {
            emitter.emit('showtime')
            this.destroy()
          }
        },
        '<90%'
      )
  }

  destroy() {
    this.el.removeEventListener('click', this.handleClick)
    this.el.remove()
  }
}
